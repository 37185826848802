import { httpApi } from '@app/api/http.api';
import { Pagination } from './table.api';
//import './mocks/auth.api.mock';

export interface UserUpdate {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  socialId?: string;
  newPW?: string;
  status?: any;
  role?: any;
}

export const getAll = (pagination: Pagination): Promise<any> =>
  httpApi
    .get<any>('users?page=' + (pagination.page || 1) + '&limit=' + (pagination.limit || 50))
    .then(({ data }) => data);

export const removeUser = (id: number): Promise<any> => httpApi.delete<any>('users/' + id).then(({ data }) => data);

export const updateUser = (id: number, user: UserUpdate): Promise<any> => {
  return httpApi.patch<any>('users/' + id, user).then(({ data }) => data);
};
